module.exports = {
  en: {
    path: 'en',
    default: true,
    variations: [
      {
        name: 'A',
        description: 'English Screener with 3 questions',
      },
      {
        name: 'B',
        description: 'English Screener with 4 questions'
      }
    ]
  }
};

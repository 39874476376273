/* Dropdown implements an HTML select component
   Supported props: items, name, defaultItem, ddStyle, responder
*/

import React from 'react';

function defaultHandler(e) { console.log('Selection from dropdown was made ->', e.target.value); }

export default function Dropdown(props) {
  let options = [];
  if (props.items) {
    options = props.items.map((item, index) => {
      if (item.hasOwnProperty('id')) return <option key={`opt_${index}`} value={item.id}>{item.value}</option>
      return <option key={`opt_${index}`}>{item.value}</option>
    });
  }
  return (
    <select
       name={props.name}
       className={props.ddStyle || ""}
       onChange={props.responder || defaultHandler}>
      <option>{props.defaultItem}</option>
      {options}
    </select>
  );
};

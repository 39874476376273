/**
 * A log function that can be "muted" by a configuration setting.
 */
const sitedata = {nologs: false};
let IS_SILENT = Boolean(sitedata.nologs);

export default function log(message, attachment) {
  if (IS_SILENT) return;
  if (attachment) console.log(message, attachment);
  else            console.log(message);
};

/**
 * Footer produces a footer containing two horizontally placed panels.
 * Supported props: src localizerfn
 */
import React from 'react';
import { Link } from 'gatsby';
import LinkMenu from './LinkMenu';
import FbShare from './FbShare';
import TwShare from './TwShare';
import logo from '../images/logo_footer.png';

export default function Footer(props) {
  const cfg = props.src;
  const footlinks = cfg.toplinks.concat(cfg.links);
  const thispage = `${cfg.siteurl}${props.location ? props.location.pathname : '/'}`;
  const localized = props.localizerfn;

  return (
    <footer className="footer container-fluid">
      <div className="row">
        <div className="col-xs-12 col-sm-8">
          <div id="footer-logo">
            <Link to={localized('/')}>
              <img src={logo} alt={cfg.sitename} className="img-responsive" />
            </Link>
          </div>
          <p className="mx-auto">{cfg.text}</p>
        </div>
        <div className="col-xs-10 col-sm-4 container">
          <h1 className="share-button-label">{cfg.socialLinkLabel}</h1>
          <div className="share-buttons">
            <FbShare pageUrl={thispage} />
            <TwShare pageUrl={thispage} />
          </div>
        </div>
      </div>
      <LinkMenu links={footlinks} listStyle="footer-links" localizerfn={localized} />
    </footer>
  );
};

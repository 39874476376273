/* A content section comprised only of a header and one or
 * more paragraphs of text.
 * Supported props: src
*/

import React from 'react';

export default function BasicSection(props) {
  const cfg = props.src;
  const fg = (cfg.fg ? 'text-'+cfg.fg : '');
  const bg = (cfg.bg ? 'bg-'+cfg.bg : '');
  const sectionStyle = `basic-section section-indented ${bg} ${fg}`.trim();
  const bodyStyle = `col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2${addclass(cfg.bodyClass)}`.trim();
  const headerStyle = `section-header ${cfg.headerclass || ''}`.trim();
  const pStyle = ((!cfg.bg || cfg.bg==='white') ? 'text-black' : fg);

  return (
    <div id={cfg.id} className={sectionStyle}>
      <div className="container-fluid">
        <div className="row">
          <div className={bodyStyle}>
            <h1 className={headerStyle}><span>{cfg.header || cfg.title}</span></h1>
            {renderParagraphs(cfg.text, pStyle, cfg.displayAs)}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(parags, styling, displayType) {
  if (displayType==='list') {
    return <ul>{parags.map((item, index) => <li key={index} className={styling}>{item}</li> )}</ul>;
  } else
  if (displayType==='faq') {
    return parags.map((item, index) => {
      const [q, ans] = item.split('?');
      return (
        <div key={index}>
          <p className={`faq ${styling}`}>{q+'?'}</p>
          <p className={styling}>{ans}</p>
        </div>
      );
    });
  }
  return parags.map((item, index) => <p key={index} className={styling}>{item}</p> );
}

function addclass(name) { return (name ? ` ${name}` : ''); }


/**
 * A Screener is a container for Slides with logic for conducting a survey.
 * Supported props: config, sitedata, localizerfn
 */
import React from 'react';
import { navigate } from 'gatsby';
import Slide from './Slide';
import PatientForm from './PatientForm';
import LocationsForm from './LocationsForm';
import pagetools from '../util/pagetools';
import log from '../util/logger';

class Screener extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlideIndex: 0,
      visitedSlideIndex: 0,
      isQualified: false,
      patientData: {}
    };
    this.isBrowser = (typeof window !== 'undefined');
    this.advancer = this.advancer.bind(this);
    this.collector = this.collector.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.getPatientLocation = this.getPatientLocation.bind(this);
    this.lastQuestionIndex = props.config.questions.length-1;
    this.successPageIndex = props.config.questions.length;
    this.locationsIndex = props.config.questions.length+1;
    this.failPageIndex = props.config.questions.length+2;
    this.endpoint = '/.netlify/functions/screenerResults';
    this.sendToFB = this.sendToFB.bind(this);
    this.sendToGA = this.sendToGA.bind(this);
    this.sendToGA_done = this.sendToGA_done.bind(this);
  }

  componentDidMount() {
    const callback = pagetools.adjustSlideHeight;
    function watchResize() { window.addEventListener('resize', callback); }
    watchResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', pagetools.adjustSlideHeight);
  }

  /**
   * Changes active slide based on user input.
   * @param int - the index of a slide
   * @param boolean - a boolean user response
   */
  handleScreenerAction(slideIndex, slideReply) {
    const cfg = this.props.config;
    const canProceed = cfg.questions[slideIndex].proceed(slideReply);
    this.sendToGA(slideIndex, slideReply);
    if (canProceed) {
      this.nextSlide(this.state.activeSlideIndex+1);
      if (slideIndex>=this.lastQuestionIndex) {
        this.sendToFB(true);
        this.sendToGA_done(true);
        this.setState({isQualified: true});
      }
    } else {
      // Disqualification is indicated by setting activeSlideIndex to index of the fail page
      this.sendToFB(false);
      this.sendToGA_done(false);
      this.nextSlide(this.failPageIndex);
    }
  }

  /**
   * Convenience function to switch to a given slide by its index.
   * @param int - the index of a slide
   * @return none
   */
  nextSlide(slideIndex) {
    this.setState({activeSlideIndex: slideIndex});
    this.setState({visitedSlideIndex: slideIndex});
  }

  /**
   * High order function to provide an event handler
   * @param int - the index of a slide
   * @param boolean - a boolean user response
   * @return function
   */
  advancer(slideIndex, slideReply) {
    const screener = this;
    return function(event) { screener.handleScreenerAction(slideIndex, slideReply); };
  }

  /**
   * Sets the patient data from a form.
   * @param object - field values from a form
   */
  collector(obj) {
    this.setState({patientData: obj});
    if (this.state.isQualified) {
      // Pick a location
      this.nextSlide(this.locationsIndex);
    } else {
      // Send the patient data and navigate to thank you page
      this.sendPatientData(obj, this.endpoint);
      this.navigateToConclusion(false);
    }
  }

  /**
   * Send patient information to a specified endpoint.
   * @param object - the patient information
   * @param string - a backend end point
   * @return
   */
  sendPatientData(payload, endpoint) {
    if (!endpoint) {
      log('Currently not sending to backend. Payload=', payload);
      return;
    }
    fetch(endpoint, {
      method: 'POST',
      'Content-Type': 'application/json',
      body: JSON.stringify(payload)
    }).then(resp => {
      return resp.json();
    }).then(data => {
      log('Data sent OK', data);
    }).catch(error => {
      log('Error with form submission:', error);
      log('Payload=', JSON.stringify(payload, null,2));
      navigate(this.props.localizerfn('/generalError/'), {state: {description: error.message}});
    });
  }

  /**
   * Add the selected location to patient information, send to backend, and navigate to conclusion
   * @param object - the selected study location
   */
  setLocation(locationdata) {
    this.setState({patientData: Object.assign(this.state.patientData, {studysite: locationdata})});
    this.sendPatientData(this.state.patientData, this.endpoint);
    this.navigateToConclusion(true, Boolean(this.state.patientData.studysite));
  }

  /**
   * Navigate to the conclusion page specifying qualification of candidate.
   * @param boolean - is the candidate qualified
   * @param boolean - has a location been selected
   */
  navigateToConclusion(isQualified, locationSelected) {
    const pageProps = {
      header: '',
      subheader: '',
      text: '',
      sitedata: this.props.sitedata
    }
    if (isQualified) {
      const success = this.props.config.successResult;
      pageProps.header = success.thankyou.header;
      pageProps.subheader = (locationSelected ? success.thankyou.subWLocation : success.thankyou.subheader);
      pageProps.text = success.thankyou.text;
      if (success.thankyou.bg) pageProps.bg = success.thankyou.bg;
    } else {
      const fail = this.props.config.failResult;
      pageProps.header = fail.thankyou.header;
      pageProps.subheader = fail.thankyou.subheader;
      pageProps.text = fail.thankyou.text;
      if (fail.thankyou.bg) pageProps.bg = fail.thankyou.bg;
    }
    navigate(this.props.localizerfn('/thankyou/'), {state: pageProps});
  }

  /**
   * Retrieve patient location from the state.
   * @return object
   */
  getPatientLocation() {
    return { lat: this.state.patientData.lat, lng: this.state.patientData.lng };
  }

  /**
   * Send data to Facebook.
   * @param boolean - is the candidate qualified
   */
  sendToFB(isQualified) {
    if (this.isBrowser && window.fbq) {
      window.fbq('trackCustom', 'PreScreenerFinish', {status: (isQualified ? 'PD' : 'DQ')});
    }
  }

  /**
   * Send data to Google Analytics for an individual screener question.
   * @param int - the index of the slide (zero-based)
   * @param boolean - whether or not candidate answered "Yes"
   */
  sendToGA(index, isAffirmative) {
    if (this.isBrowser) {
      const stage = (index===0 ? 'screener_start' : 'screener_progress');
      const evLabel = 'Q'+(index+1)+(isAffirmative?'Y':'N');
      if (window.gtag) {
        window.gtag('event', stage, { 'event_category': 'Screener', 'event_label': evLabel });
      }
    }
  }

  /**
   * Send data to Google Analytics for screener conclusion.
   * @param boolean - is the candidate qualified
   */
  sendToGA_done(isQualified) {
    if (this.isBrowser && window.gtag) {
      window.gtag('event', 'screener_finish', { 'event_category': 'Screener', 'event_label': `Screener finished ${(isQualified ? 'Q' : 'DQ')}` });
    }
  }

  render() {
    const cfg = this.props.config;
    const site = this.props.sitedata;
    const questions = cfg.questions;
    const locations = cfg.locations;
    return (
      <section id="screener" className="screener-section">
        <div className="screener">
          {questions.map((item,idx) => (
            <Slide key={`q-${idx}`}
                   idx={idx}
                   q={item.q}
                   qLabel={`${cfg.questionLabel.noun} ${idx+1} ${cfg.questionLabel.prep} ${questions.length}`}
                   affirm={cfg.questionLabel.affirm}
                   deny={cfg.questionLabel.deny}
                   isActive={this.state.activeSlideIndex===idx}
                   isPast={this.state.visitedSlideIndex>=idx}
                   bg={item.bg}
                   fg={item.fg}
                   responder={this.advancer}
            />
          ))}
          <Slide key="success-page"
                 idx={this.successPageIndex}
                 isActive={(this.state.activeSlideIndex===this.successPageIndex)}
                 isPast={this.state.visitedSlideIndex>=this.successPageIndex}>
            <h1 className="slide-h1">{cfg.successResult.header}</h1>
            <h2 className="slide-h2">{cfg.successResult.message}</h2>
            {this.state.isQualified &&
              <PatientForm onSubmit={this.collector}
                           sitedata={site}
                           formAction={site.patientFields.cta}
                           isQualified={true} />
            }
          </Slide>
          <Slide key="locations-page"
                 idx={this.locationsIndex}
                 isActive={(this.state.activeSlideIndex===this.locationsIndex)}
                 isPast={this.state.visitedSlideIndex>=this.locationsIndex}
                 styleInfo="bg-locations">
            <div className="location-header">{locations.cta}</div>
            <div className="location-desc">{locations.header} "<strong>{locations.noSuitableSite}</strong>" {locations.subheader}
            </div>
            <LocationsForm patientLoc={this.getPatientLocation()} locations={locations} setLocation={this.setLocation} />
          </Slide>
          <Slide key="fail-page"
                 idx={this.failPageIndex}
                 isActive={(this.state.activeSlideIndex===this.failPageIndex)}>
            <h1 className="slide-h1">{cfg.failResult.header}</h1>
            <h2 className="slide-h2">{cfg.failResult.message}</h2>
            {!this.state.isQualified &&
              <PatientForm onSubmit={this.collector}
                           sitedata={site}
                           formAction={site.patientFields.ctaDq}
                           isQualified={false} />
            }
          </Slide>
        </div>
      </section>
    );
  }
}
export default Screener;

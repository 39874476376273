/**
 * Transforms a given screener configuration object by adding a function to
 * each question which implements logic for proceeding, that is, a function which
 * tells which response to a given question is the correct one.
 */

/**
 * High-order function that returns logic determining correctness of question response
 * @param boolean
 * @return function
 */
function getProceedFn(proceedIfYes) {
  return (proceedIfYes ? (reply) => Boolean(reply) : (reply) => !Boolean(reply));
}

/**
 * Make a copy of a screener configuration, adding a function for each question.
 * @param object - a screener configuration object
 * @return object
 */
export default function addProceedLogic(config) {
  if (!config.hasOwnProperty('questions')) return config;
  if (!Array.isArray(config.questions)) return config;
  const newConfig = Object.assign({}, config);
  // Add a "proceed" function to each question
  newConfig.questions = config.questions.map(q => ({...q, proceed:getProceedFn(q.proceedIfYes)} ));
  return newConfig;
};

/**
 * A panel which appears at a fixed location on scrolling into a given range.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

export default function FixedPanel(props) {
  const [hidden, setHidden] = useState(true);
  const rootStyle = (hidden ? 'begin-fixed hidden' : 'begin-fixed');

  function getTop(el) {
    const rect = el.getBoundingClientRect();
    return rect.top + (window.pageYOffset || document.documentElement.scrollTop);
  }

  useEffect(() => {
    const banner = document.getElementById('banner');
    const screener = document.getElementById('screener');
    function enablePanel() {
      let lowerBoundary = (banner ? banner.scrollHeight+getTop(banner) : 0);
      let upperBoundary = (screener ? getTop(screener) : window.innerHeight) - 200;
      setHidden(window.scrollY<lowerBoundary || window.scrollY>upperBoundary);
    }
    function watchScroll() { window.addEventListener('scroll', enablePanel); }
    watchScroll();
    // Remove the listener
    return () => { window.removeEventListener('scroll', enablePanel); };
  }, []);

  return (
    <div className={rootStyle}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-8 col-sm-9 begin-fixed-content">
            {props.cta}, <span className="text-orange">{props.teaser}</span>
          </div>
          <div className="col-xs-4 col-sm-3">
            <Link to="#screener" className="get-started-btn">{props.btnLabel}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

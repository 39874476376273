/**
 * Banner provides a container with a title, a button, and an image.
 */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export default function Banner(props) {
  const cfg = props.src;
  return (
    <div id="banner" className="row banner bg-pary-dark">
      <div className="col-xs-12 col-sm-6">
        <div className="banner-left-content">
          <h1 className="banner-title">{cfg.title}</h1>
          <div className="banner-text">{cfg.subtitle}</div>
          <div className="banner-teaser">{cfg.teaser}</div>
          <div className="my-2">
            <Link to="#screener" className="get-started-btn">{cfg.startstudy}</Link>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 banner-img-container">
        <StaticImage src='../images/banner.jpg' alt="main banner" layout="fullWidth" className="hidden-xs" />
        <StaticImage src='../images/banner_mobile.jpg' alt="main banner" layout="fullWidth" className="visible-xs" />
      </div>
    </div>
  );
};
